<template>
  <div class="wallet-topup-button">
    <div class="wtb__desc">
      <div class="wtb__type">
        <h6>{{productName}}</h6>
      </div>
      <div
        class="wtb__subdesc"
        v-if="hasRecharge"
      >
        <p>
          {{entryVal}}
        </p>
      </div>
      <div
        class="wtb__subdesc"
        v-if="hasPrepaid"
      >
        <b-row v-for="item in prepaidList" :key="item.key">
          <b-col md="9"><h6>{{item.name}}</h6></b-col>
          <b-col md="3"><h6>{{item.amount}}</h6></b-col>
        </b-row>
      </div>
      <div
        class="wtb__subdesc"
        v-if="valTrueVal !== null"
      >
        <p>
          {{valTrueVal}}
        </p>
      </div>
      <div
        class="wtb__cockade"
        v-if="hasDiscount"
      >
        <font-awesome-icon icon="certificate"/>
        <div class="wtb__cockade__value">
          {{discountValue}}
        </div>
      </div>
    </div>
    <div
      v-if="isOperative"
      class="wtb__actions"
    >
      <b-button
        variant="outline-primary"
        @click="doTopUp()"
        :disabled="disabled"
        size="sm"
      >
        <font-awesome-icon icon="credit-card"/>
      </b-button>
    </div>
  </div>
</template>

<script>

import { formatDecimalNumber } from '@/utils/formatterHelper';
import { isNotEmpty } from '@/utils/validators';

export default {
  name: 'WalletButton',
  components: {},
  props: {
    item: Object,
    buyProduct: Function,
    priceDenominator: Number,
    disabled: Boolean,
  },
  computed: {
    entryVal() {
      return `Ricarica da ${this.item.creditsPurchased.amount} ${this.item.creditsPurchased.currency}`;
    },
    productName() {
      return this.item.description ? this.item.description : this.entryVal;
    },
    valTrueVal() {
      // val difference - discount
      return `Al prezzo di ${this.item.price.amount} ${this.item.price.currency}`;
    },
    hasDiscount() {
      return this.discount > 0;
    },
    discount() {
      // const myRatio = this.item.creditsPurchased.amount / this.item.price.amount;
      // const percent = (myRatio - this.priceDenominator) / this.priceDenominator;
      let purchase = this.item.creditsPurchased.amount;
      const lista = this.prepaidList;
      lista.forEach((item) => {
        const { price } = this.$store.getters['wallet/getPricing'](item.product, item.code);
        if (price) {
          purchase += price.amount * item.amount;
        }
      });
      // console.log('discout price', lista, purchase);
      const percent = (purchase - this.item.price.amount) / this.item.price.amount;
      return percent > 0 ? percent : 0;
    },
    discountValue() {
      return `${formatDecimalNumber(this.discount * 100, 0, 'it')}% off`;
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    hasRecharge() {
      return this.item.creditsPurchased.amount > 0;
    },
    prepaidList() {
      const { productsTypePrePaid } = this.item;
      if (!productsTypePrePaid) return [];
      const prepaids = [];

      const productTypes = Object.keys(productsTypePrePaid);
      productTypes.forEach((product) => {
        const { productsCodePrePaid } = productsTypePrePaid[product];
        if (productsCodePrePaid) {
          const productCodes = Object.keys(productsCodePrePaid);
          const lista = this.$store.getters['tableUtils/getTableList'](product) || [];
          productCodes.forEach((code) => {
            const prod = lista.find((item) => item.key === code);
            if (prod) {
              if (productsCodePrePaid[code] > 0) {
                prepaids.push({
                  key: `${product}_${code}`,
                  code,
                  product,
                  name: prod.value,
                  amount: productsCodePrePaid[code],
                });
              }
            }
          });
        }
      });

      return prepaids;
    },
    hasPrepaid() {
      return this.prepaidList.length > 0;
    },
  },
  methods: {
    doTopUp() {
      // console.log('show top-up modal/confirm', this.item.creditsPurchased.amount);
      if (this.buyProduct) {
        this.buyProduct(this.item);
      }
    },
  },
};
</script>

<style scoped>

</style>
